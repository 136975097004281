<template>
  <div>
    <b-modal
      id="modal-form-land"
      centered
      hide-footer
      size="lg"
      :title="modalTitle"
    >
      <b-card-text>
        <b-alert
          v-if="errors.length > 0"
          variant="danger"
          show
        >
          <div class="alert-body">
            <p
              v-for="error in errors"
              :key="error"
            >
              {{ error }}
            </p>
          </div>
        </b-alert>
        <b-row>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Nama Tanah"
            >
              <b-form-input
                v-model="form.name"
                placeholder="Masukkan Nama Tanah"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Luas"
            >
              <b-input-group append="m2">
                <b-form-input
                  v-model="form.wide"
                  type="number"
                  placeholder="Masukkan Luas"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            cols="12"
          >
            <b-form-group
              label="Alamat"
            >
              <b-form-textarea
                v-model="form.address"
                placeholder="Masukkan Alamat"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="File Pendukung"
            >
              <b-form-file
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="handleSave()"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span>Simpan</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BAlert, BModal, VBModal, BCardText, BButton, BFormInput, BFormGroup, BInputGroup, BFormTextarea, BRow, BCol, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAlert,
    BModal,
    BCardText,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BRow,
    BCol,
    BFormFile,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Tambah Alat',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    categoryList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      errors: [],
      file: null,
    }
  },
  methods: {
    async handleSave() {
      try {
        const formData = new FormData()
        formData.append('name', this.form.name)
        formData.append('wide', this.form.wide)
        formData.append('address', this.form.address)
        formData.append('file', this.file)
        formData.append('type', 'land')

        if (this.form.id) {
          await this.$http.post(`/v1/infrastructure/${this.form.id}`, formData)
        } else {
          await this.$http.post('/v1/infrastructure', formData)
        }

        this.$bvModal.hide('modal-form-land')
        this.$emit('success')
        this.$emit('refresh')
      } catch (error) {
        this.errors = error.response.data.errors
        this.$emit('error', error.response.data.messages)
      }
    },
  },
}
</script>
