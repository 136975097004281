<template>
  <div>
    <b-modal
      id="modal-form-building"
      centered
      hide-footer
      size="lg"
      :title="modalTitle"
    >
      <b-card-text>
        <b-alert
          v-if="errors.length > 0"
          variant="danger"
          show
        >
          <div class="alert-body">
            <p
              v-for="error in errors"
              :key="error"
            >
              {{ error }}
            </p>
          </div>
        </b-alert>
        <b-row>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Nama Bangunan"
            >
              <b-form-input
                v-model="form.name"
                placeholder="Masukkan Nama Bangunan"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Lahan"
            >
              <b-form-select
                v-model="form.parent.id"
                :options="landList"
                placeholder="Pilih Lahan Tanah"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-card class="border">
              <b-form-group
                label="Lantai"
              >
                <b-form
                  ref="form"
                  :style="{height: trHeight}"
                  class="repeater-form"
                  @submit.prevent="repeateAgain"
                >
                  <b-row
                    v-for="(floor, index) in form.floors"
                    :id="floor.floorId"
                    :key="floor.floorId"
                    ref="row"
                  >

                    <!-- Floor Name -->
                    <b-col md="6">
                      <b-form-group
                        label="Nama Lantai"
                        label-for="floor-name"
                      >
                        <b-form-input
                          v-model="floor.name"
                          placeholder="Masukkan Nama Lantai"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group
                        label="Luas"
                        label-for="floor-wide"
                      >
                        <b-form-input
                          v-model="floor.wide"
                          placeholder="Masukkan Luas"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="2"
                      class="mb-50"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeItem('floor', index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mt-1"
                  @click="repeateAgain('floor')"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Tambah Lantai</span>
                </b-button>
              </b-form-group>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-card class="border">
              <b-form-group
                label="Basement"
              >
                <b-form
                  ref="form"
                  :style="{height: trHeight}"
                  class="repeater-form"
                  @submit.prevent="repeateAgain"
                >
                  <b-row
                    v-for="(basement, index) in form.basements"
                    :id="basement.basementId"
                    :key="basement.basementId"
                    ref="row"
                  >

                    <!-- basement Name -->
                    <b-col md="6">
                      <b-form-group
                        label="Nama Basement"
                        label-for="basement-name"
                      >
                        <b-form-input
                          v-model="basement.name"
                          placeholder="Masukkan Nama Basement"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group
                        label="Luas"
                        label-for="basement-wide"
                      >
                        <b-form-input
                          v-model="basement.wide"
                          placeholder="Masukkan Luas"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="2"
                      class="mb-50"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeItem('basement', index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mt-1"
                  @click="repeateAgain('basement')"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Tambah Basement</span>
                </b-button>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
      </b-card-text>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="handleSave()"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span>Simpan</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BAlert, BModal, VBModal, BForm, BCardText, BButton, BFormInput, BFormGroup, BFormSelect, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BAlert,
    BModal,
    BForm,
    BCardText,
    BButton,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    modalTitle: {
      type: String,
      default: 'Tambah Alat',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    landList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      errors: [],
      nextTodoFloorId: 1,
      nextTodoBasementId: 1,
    }
  },
  methods: {
    async handleSave() {
      try {
        this.form.type = 'building'
        this.form.parent_id = this.form.parent.id

        if (this.form.id) {
          await this.$http.post(`/v1/infrastructure/${this.form.id}`, this.form)
        } else {
          await this.$http.post('/v1/infrastructure', this.form)
        }

        this.$bvModal.hide('modal-form-building')
        this.$emit('success')
        this.$emit('refresh')
      } catch (error) {
        this.errors = error.response.data.errors
        this.$emit('error', error.response.data.messages)
      }
    },
    repeateAgain(key) {
      if (key === 'floor') {
        this.form.floors.push({
          floorId: this.nextTodoFloorId += this.nextTodoFloorId,
        })
      } else {
        this.form.basements.push({
          basementId: this.nextTodoBasementId += this.nextTodoBasementId,
        })
      }

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(key, index) {
      if (key === 'floor') {
        this.form.floors.splice(index, 1)
      } else {
        this.form.basements.splice(index, 1)
      }
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>
