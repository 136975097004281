<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <h4 class="mb-2">
          Detail Prasarana
        </h4>
        <b-row>
          <b-col>
            <b-alert
              v-if="errors.length > 0"
              variant="danger"
              show
            >
              <div class="alert-body">
                <p
                  v-for="error in errors"
                  :key="error"
                >
                  {{ error }}
                </p>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-card class="border">
          <b-overlay
            :show="overlay"
            rounded="sm"
          >
            <b-row class="my-2">
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    {{ '1. ' + detail.land.label }}
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-items-center justify-content-end mb-1">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-form-land
                        variant="primary"
                        size="sm"
                        @click="handleCreate('land')"
                      >
                        <span>Tambah</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-table
                  class="position-relative"
                  :items="detail.land.list"
                  small="true"
                  :fields="landColumns"
                  primary-key="id"
                  show-empty
                  empty-text="No matching records found"
                >
                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item
                        v-b-modal.modal-form-land
                        @click="handleEdit(data.item)"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="handleDelete(data.item.id)"
                      >
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    {{ '2. ' + detail.building.label }}
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-items-center justify-content-end mb-1">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-form-building
                        variant="primary"
                        size="sm"
                        @click="handleCreate('building')"
                      >
                        <span>Tambah</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-table
                  class="position-relative"
                  :items="detail.building.list"
                  small="true"
                  responsive="sm"
                  :fields="buildingColumns"
                  primary-key="id"
                  show-empty
                  empty-text="No matching records found"
                >
                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item
                        v-b-modal.modal-form-building
                        @click="handleEdit(data.item)"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="handleDelete(data.item.id)"
                      >
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
        <b-card class="border">
          <b-overlay
            :show="overlay"
            rounded="sm"
          >
            <b-row>
              <b-col
                v-for="(common, index) in detail.common"
                :key="common.id"
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="(index+3) + '. ' + common.label"
                >
                  <b-form-group
                    label="Luas"
                    label-cols-md="5"
                  >
                    <b-input-group append="m2">
                      <b-form-input
                        v-model="common.wide"
                        type="number"
                        placeholder="Masukkan Luas"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
        <b-card class="border">
          <b-overlay
            :show="overlay"
            rounded="sm"
          >
            <b-row>
              <b-col
                v-for="(other, index) in detail.other"
                :key="other.id"
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="(index+detail.common.length+3) + '. ' + other.label"
                >
                  <div v-if="other.type == 'public facility'">
                    <b-form-group
                      label="Jalan"
                      label-cols-md="5"
                    >
                      <b-form-input
                        v-model="other.road_type"
                        placeholder="Masukkan Tipe Jalan"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Air"
                      label-cols-md="5"
                    >
                      <b-form-input
                        v-model="other.water_sources"
                        placeholder="Masukkan Sumber Air"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Listrik"
                      label-cols-md="5"
                    >
                      <b-input-group append="watt">
                        <b-form-input
                          v-model="other.electrical_power"
                          type="number"
                          placeholder="Masukkan Daya Listrik"
                        />
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      label="Jaringan Komunikasi Suara"
                      label-cols-md="5"
                    >
                      <b-form-input
                        v-model="other.voice_communication_network"
                        placeholder="Masukkan Jaringan Komunikasi Suara"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Data"
                      label-cols-md="5"
                    >
                      <b-form-input
                        v-model="other.internet_network"
                        placeholder="Masukkan Jaringan Internet"
                      />
                    </b-form-group>
                  </div>
                  <div v-else-if="other.type == 'special needs'">
                    <b-form-group
                      label="A. Pelabelan dengan tulisan braille dan informasi dalam bentuk suara"
                      label-cols-md="10"
                    >
                      <b-form-checkbox
                        v-model="other.is_braille_writing_and_sound_form"
                      />
                    </b-form-group>
                    <b-form-group
                      label="B. Lerengan (ramp) untuk pengguna kursi roda"
                      label-cols-md="10"
                    >
                      <b-form-checkbox
                        v-model="other.is_wheelchair_user"
                      />
                    </b-form-group>
                    <b-form-group
                      label="C. Jalur pemandu (guiding block) di jalan atau koridor"
                      label-cols-md="10"
                    >
                      <b-form-checkbox
                        v-model="other.is_guiding_block"
                      />
                    </b-form-group>
                    <b-form-group
                      label="D. Peta/denah kampus"
                      label-cols-md="10"
                    >
                      <b-form-checkbox
                        v-model="other.is_campus_map"
                      />
                    </b-form-group>
                    <b-form-group
                      label="E. Toilet/kamar mandi pengguna kursi roda"
                      label-cols-md="10"
                    >
                      <b-form-checkbox
                        v-model="other.is_wheelchair_user_toilet"
                      />
                    </b-form-group>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="handleUpdate()"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            <span>Simpan</span>
          </b-button>
        </div>
      </div>
    </b-card>
    <ModalFormLand
      :modal-title="modalTitle"
      :form="formLand"
      @refresh="getInfrastructure()"
      @success="successToast()"
      @error="errorToast($event)"
    />
    <ModalFormBuilding
      :modal-title="modalTitle"
      :form="formBuilding"
      :land-list="landList"
      @refresh="getInfrastructure()"
      @success="successToast()"
      @error="errorToast($event)"
    />
  </div>
</template>
<script>
import {
  BOverlay, BAlert, BCard, BRow, BCol, BFormGroup, BTable, BButton, BFormInput, BInputGroup, BFormCheckbox, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import ModalFormLand from './ModalFormLand.vue'
import ModalFormBuilding from './ModalFormBuilding.vue'

export default {
  components: {
    BOverlay,
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BButton,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,

    ModalFormLand,
    ModalFormBuilding,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      overlay: true,
      form: {
        infrastructures: [],
      },
      formLand: {},
      formBuilding: {
        parent: {
          id: null,
        },
      },
      errors: [],
      detail: {},
      landList: [],
      landColumns: [
        {
          key: 'no',
        },
        {
          key: 'name',
          label: 'Nama Tanah',
        },
        {
          key: 'wide',
          label: 'Luas Tanah',
        },
        {
          key: 'actions',
          label: 'Aksi',
        },
      ],
      buildingColumns: [
        {
          key: 'no',
        },
        {
          key: 'name',
          label: 'Nama Bangunan',
        },
        {
          key: 'parent.name',
          label: 'Nama Lahan',
        },
        {
          key: 'wide',
          label: 'Luas Gedung',
        },
        {
          key: 'actions',
          label: 'Aksi',
        },
      ],
    }
  },
  mounted() {
    this.getInfrastructure()
  },
  methods: {
    getInfrastructure() {
      this.$http.get('/v1/infrastructure')
        .then(response => {
          this.detail = response.data.data
          this.overlay = false

          this.landList = response.data.data.land.list.map(item => ({
            value: item.id,
            text: item.name,
          }))

          this.landList.unshift({
            value: null,
            text: 'Pilih Tanah',
          })
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    handleUpdate() {
      this.form.infrastructures = []

      this.detail.common.forEach(common => {
        this.form.infrastructures.push({
          id: common.id,
          wide: common.wide,
        })
      })

      this.detail.other.forEach(other => {
        if (other.type === 'public facility') {
          this.form.infrastructures.push({
            id: other.id,
            road_type: other.road_type,
            water_sources: other.water_sources,
            electrical_power: other.electrical_power,
            voice_communication_network: other.voice_communication_network,
            internet_network: other.internet_network,
          })
        } else {
          this.form.infrastructures.push({
            id: other.id,
            is_braille_writing_and_sound_form: other.is_braille_writing_and_sound_form,
            is_wheelchair_user: other.is_wheelchair_user,
            is_guiding_block: other.is_guiding_block,
            is_campus_map: other.is_campus_map,
            is_wheelchair_user_toilet: other.is_wheelchair_user_toilet,
          })
        }
      })

      this.$http.put('/v1/infrastructure/bulk_update', this.form)
        .then(() => {
          this.successToast()
          this.getInfrastructure()
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.errorToast(error.response.data.messages)
        })
    },
    handleCreate(type) {
      if (type === 'land') {
        this.modalTitle = 'Tambah Data Lahan Tanah'
        this.formLand = {}
      } else {
        this.modalTitle = 'Tambah Data Lahan Bangunan'
        this.formBuilding = {}
      }
    },
    handleEdit(data) {
      if (data.type === 'land') {
        this.modalTitle = 'Edit Data Lahan Tanah'
        this.formLand = data
      } else {
        this.modalTitle = 'Edit Data Lahan Bangunan'
        this.formBuilding = data
      }
    },
    handleDelete(id) {
      this.$http.delete(`/v1/infrastructure/${id}`)
        .then(() => {
          this.successToast()
          this.getInfrastructure()
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    successToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    errorToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>
